<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
  >
    <b-row>
      <form-input
        label="Title"
        v-model="form.title"
        data-vv-as="Title"
        :col="12"
      />

      <form-input
        label="Detail"
        v-model="form.detail"
        data-vv-as="Detail"
        :col="12"
        textarea
      />

      <form-upload-image
        label="Upload Image"
        :col="12"
        :max="3"
        ref="uploadImage"
        :images="form.image_datas"
        :pathImage="pathImage"
      />
    </b-row>
  </form-modal>
</template>
<script>
export default {
  props: {
    pathImage: "",
  },
  computed: {
    _title() {
      return (this.form.id ? "Update " : "Create") + " Call Automation Data"
    },
  },
  data() {
    return {
      form: {
        id: "",
        title: "",
        detail: "",
        system: "",
        ranking: "",
        image_datas: [],
      },
    }
  },
  methods: {
    show(datas = "", ranking = 0) {
      console.log(datas)
      this.clearForm()
      if (datas) {
        const _url = process.env.VUE_APP_API_BASEURL
        this.form = datas
      } else {
        this.form.ranking = ranking
        this.form.title = `step - ${ranking + 1}`
      }

      this.switch("show")
    },
    async handleSubmit() {
      try {
        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }
        const form = this.cp(this.form)
        form["system"] = "CallAutomation"
        const resp = await this.api.createOrUpdate(
          `/api/admin/timeLineData`,
          form,
          "Hacs Integration"
        )
        await this.$refs.uploadImage.uploadImage(resp.id, form["system"])
        this.$emit("reload", "")
        this.switch("close")
      } catch (error) {
        console.error(error)
        return "Hacs Integration"
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
